import * as Sentry from "@sentry/nextjs";
import getConfig from "next/config";

/*
 * Make everything from "@sentry/nextjs" available via this module ("@/sentry").
 */
export * from "@sentry/nextjs";

if (process.env.SENTRY_DSN) {
  Sentry.init({
    enabled: process.env.NODE_ENV === "production",
    dsn: process.env.SENTRY_DSN,
    release: process.env.VERSION,

    integrations: [
      Sentry.rewriteFramesIntegration({
        iteratee: (frame) => {
          frame.filename = frame.filename?.replace(`${getConfig().serverRuntimeConfig.rootDir}/.next`, "app:///_next");
          return frame;
        },
      }),

      ...(() => {
        if (process.browser) {
          return [Sentry.browserTracingIntegration()];
        } else {
          return [Sentry.httpIntegration()];
        }
      })(),
    ],

    /*
     * We keep the traces sample rate such that we collect about 10k transactions
     * per month. The sample rate needs to be regularly adjusted depending on the
     * load (~ number of visitors) which can change over time.
     */
    tracesSampler: (samplingContext) => {
      /*
       * Completely ignore /lobby transactions. This page is being constantly reloaded
       * on a timer and thus generates a constant stream of transactions.
       */
      if (samplingContext.location?.pathname === "/lobby") {
        return 0;
      }

      return 0.2;
    },

    ignoreErrors: [
      /*
       * This is not an actual error, and there doesn't seem to be any other way
       * to suppress that.
       *
       * See https://forum.sentry.io/t/resizeobserver-loop-limit-exceeded/8402/5
       */
      "ResizeObserver loop limit exceeded",
      "ResizeObserver loop completed with undelivered notifications.",

      /*
       * See https://github.com/getsentry/sentry-javascript/issues/3440#issuecomment-954602174
       */
      "Non-Error promise rejection captured",

      /*
       * May be because we're somehow misusing Next.js links, or a bug in Next.js.
       *
       * Check if this error still appears after we upgrade Next.js to v14 or later.
       */
      /Invariant: attempted to hard navigate to the same URL/,

      /*
       * We only support modern browsers, but somebody with Chrome 84 (released July 14, 2020)
       * keeps accessing the website.
       */
      /requestAnimationFrame is not defined/,
    ],

    beforeSend(event, hint) {
      /*
       * Oh Google…
       *
       * See https://github.com/getsentry/sentry-javascript/issues/2514#issuecomment-603971338
       */
      if (hint?.originalException === "Timeout") {
        return null;
      }

      return event;
    },

    denyUrls: [/cyp\.jobbase\.io\//i, /fast\.wistia\.com\//i],
  });
}
